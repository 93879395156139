import { getCurrentUser, getUserPreferences, getUsers } from "@/api/users";
import { queryOptions } from "@tanstack/react-query";

const usersQueries = {
  all: () => ["users"] as const,
  lists: () => [...usersQueries.all(), "list" as const] as const,
  list: () =>
    queryOptions({
      queryKey: [...usersQueries.lists()] as const,
      queryFn: getUsers,
    }),
  preferences: () =>
    queryOptions({
      queryKey: [...usersQueries.all(), "userPreferences"],
      queryFn: getUserPreferences,
    }),
  currentUser: () =>
    queryOptions({
      queryKey: [...usersQueries.all(), "currentUser"],
      queryFn: getCurrentUser,
      staleTime: 50000,
    }),
};

export default usersQueries;
