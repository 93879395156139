const blue = {
  50: "#E8EDF4",
  100: "#D0DCE8",
  200: "#B9CADD",
  300: "#8AA7C6",
  400: "#5B83AF",
  500: "#4371A3",
  600: "#2C6098",
  700: "#144E8C",
  800: "#12467E",
  900: "#103E70",
  A100: "#D0DCE8",
  A200: "#B9CADD",
  A400: "#5B83AF",
  A700: "#144E8C",
};

export default blue;
