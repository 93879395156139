import { viewTypeSchema } from "@/schemas";
import { components } from "./api";
import { z } from "zod";

export class DetailedError extends Error {
  constructor(
    message: string,
    public details: components["schemas"]["ErrorResponseDTO"]["errorDetails"]
  ) {
    super(message);
  }
}

export type ViewType = z.infer<typeof viewTypeSchema>;

export type TotalByMaterial = Record<string, Record<string, number>>;
// eg: { "sand" : "tons": 100}
