import { createFileRoute, Outlet } from "@tanstack/react-router";
import { z } from "zod";
import { utcDateString } from "@/schemas";
import { components } from "@/types/api";
import dayjs from "dayjs";

const TODAY = dayjs.utc().startOf("day");

const searchParamSchema = z.object({
  q: z.string().catch(""),
  from: utcDateString.catch(TODAY.toISOString()),
  to: utcDateString.catch(TODAY.toISOString()),
  selectedJobs: z
    .array(z.custom<components["schemas"]["CustomerJobWithRowIdDTO"]>())
    .optional()
    .catch([]),
  productId: z.string().optional().catch(""),
});

export const Route = createFileRoute("/_authenticated/tickets")({
  component: () => <Outlet />,
  validateSearch: (search) => searchParamSchema.parse(search),
});
