import React from "react";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AuthContext } from "../auth";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { red } from "@mui/material/colors";
import blue from "@/utils/colors/blue";
interface RootRouterContext {
  auth: AuthContext;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function Providers({ children }: { children: React.ReactNode }) {
  const defaultTheme = createTheme({
    palette: {
      secondary: {
        main: "#FFC20E",
      },
      primary: {
        main: blue[700],
      },
      success: {
        main: "#379F7D",
      },
    },
    spacing: 4,
    typography: {
      fontFamily: ["Inter", "system-ui", "-apple-system"].join(","),
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: `${blue[50]} !important`,
            border: `2px solid ${blue[50]}`,
            transition: "border-color 0.1s",
            borderRadius: "8px !important",
            "::after": {
              display: "none",
            },
            "::before": {
              display: "none",
            },
            // on active change bg
            "&.Mui-focused": {
              backgroundColor: `white !important`,
              border: `2px solid  ${blue[700]}`,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            maxHeight: "180px",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: blue[700],

            "& .MuiTableCell-head": {
              color: "white",
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            "& .MuiTableRow-root": {
              "&:nth-of-type(even)": {
                backgroundColor: blue[50],
              },
              "&:hover": {
                backgroundColor: blue[100],
              },
            },
          },
        },
      },
      MuiTextField: {
        variants: [
          {
            props: { variant: "filled" },
            style: {
              "& .MuiInputBase-root": {
                backgroundColor: `${blue[50]} !important`,
                border: `2px solid ${blue[50]}`,
                borderRadius: "8px",
                "::after": {
                  display: "none",
                },
                "::before": {
                  display: "none",
                },

                "&:hover": {
                  backgroundColor: `${blue[100]} !important`,
                  border: `2px solid ${blue[100]}`,
                },
                "&.Mui-focused": {
                  backgroundColor: `white !important`,
                  border: `2px solid  ${blue[700]}`,
                },

                "&.Mui-error": {
                  border: `2px solid ${red[50]}`,
                  backgroundColor: `${red[50]} !important`,
                },

                "& .MuiFormLabel-root": {
                  color: `${blue[700]} !important`,
                },
              },
            },
          },
        ],
      },
      MuiFormLabel: {
        variants: [
          {
            props: {
              error: true,
            },
            style: {
              color: `${red[500]} !important`,
            },
          },
        ],
        styleOverrides: {
          root: {
            color: `${blue[700]} !important`,
          },
        },
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            backdropFilter: "blur(2px)",
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            ".MuiDataGrid-row:nth-of-type(odd):not(:hover)": {
              backgroundColor: blue[50],
            },

            ".MuiDataGrid-columnHeader--pinnedLeft, .MuiDataGrid-columnHeader--pinnedRight": {
              backgroundColor: blue[700],
            }
          },

          "columnSeparator--resizable": {
            "&:hover": {
              backgroundColor: blue[700],
            },
          },

          columnHeader: {
            color: "white",
          },
          menuIconButton: {
            color: "white",
          },
        },
      },
    },
    mixins: {
      MuiDataGrid: {
        pinnedBackground: blue[100],
        containerBackground: blue[700],
      },
    },
  });
  const DEBUG = import.meta.env.DEV;

  const devtools = localStorage.getItem("devtools") && DEBUG;

  return (
    <QueryClientProvider client={queryClient}>
      {devtools && (
        <>
          <ReactQueryDevtools initialIsOpen={false} />
          <TanStackRouterDevtools />
        </>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider maxSnack={8} autoHideDuration={5000} />
        <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export const Route = createRootRouteWithContext<RootRouterContext>()({
  component: () => (
    <Providers>
      <CssBaseline />
      <Outlet />
    </Providers>
  ),
});
