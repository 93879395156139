import { redirect } from "@tanstack/react-router";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_authenticated/users")({
  component: () => <Outlet />,
  validateSearch: z.object({
    q: z.string().optional(),
  }),
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAdmin) {
      throw redirect({
        to: "/",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});
