import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const URLS = {
  development: "https://etkt-api.guadcore.ar/",
  testing: "https://dev.api.hermeshc9.etkt.live/",
  production: "https://api.hermeshc9.etkt.live/",
};

type environment = keyof typeof URLS;

export const DEFAULT_PAGE_SIZE = 25;

const env: environment = (import.meta.env.MODE as environment) || "development";

export const API_URL = URLS[env];

export const MAX_PAGE_SIZE = 1_000_000;
export const NOW = dayjs().utc().toISOString();

export const DEFAULT_REFRESH_INTERVAL_IN_SECONDS = 5 * 60; // 5 minutes

export const DEFAULT_REFRESH_THRESHOLD_IN_SECONDS = 30; // 30 seconds

export const VISIBILITY_LEVELS = {
  CUSTOMER: "Customer",
  JOB: "Job",
  GLOBAL: "Global",
} as const;

export type VisibilityLevels =
  (typeof VISIBILITY_LEVELS)[keyof typeof VISIBILITY_LEVELS];
