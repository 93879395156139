import { AuthProvider, useAuth } from "./auth";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { Suspense, useState } from "react";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import english from "../public/locales/en/translation.json";
import { changeLanguage } from "i18next";

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    auth: undefined!,
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "en";
    resources: {
      en: typeof english;
    };
  }
}

declare global {
  interface Window {
    debugTranslations: () => void;
  }
}

console.info("Environment: ", import.meta.env.MODE);
console.info("Version: ", __APP_VERSION__);

function InnerApp() {
  const auth = useAuth();
  return <RouterProvider router={router} context={{ auth }} />;
}

export default function App() {
  // force rerender
  const [, setTick] = useState(0);

  window.debugTranslations = () => {
    void changeLanguage("cimode").then(() => setTick((x) => x + 1));
  };
  return (
    <Suspense fallback={null}>
      <AuthProvider>
        <InnerApp />
      </AuthProvider>
    </Suspense>
  );
}
