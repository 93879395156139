import { createFileRoute, Outlet } from "@tanstack/react-router";

type SetPasswordSearch = {
  token: string;
  event: "forgot" | "activate";
};
export const Route = createFileRoute("/set-password")({
  component: () => <Outlet />,
  validateSearch: (search: Record<string, unknown>): SetPasswordSearch => {
    return {
      token: search.token as string,
      event: search.event as "forgot" | "activate",
    };
  },
});
