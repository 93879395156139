/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import createClient, { Middleware } from "openapi-fetch";
import type { components, paths } from "../types/api";
import { API_URL } from "../constants";
import { DetailedError } from "../types";

const client = createClient<paths>({
  baseUrl: API_URL,
});

const jwtMiddleware: Middleware = {
  onRequest(request) {
    const token = localStorage.getItem("jwt");
    if (token) {
      request.headers.set("Authorization", `Bearer ${token}`);
    }

    return request;
  },
  onResponse(res) {
    const isLogin = res.url.includes("login");
    if (res.status === 401 && !isLogin) {
      localStorage.removeItem("jwt");
      window.location.href = "/login";
    }

    return res;
  },
};

const throwOnError: Middleware = {
  async onResponse(res) {
    if (!res.ok) {
      const body: components["schemas"]["ErrorResponseDTO"] = await res
        .clone()
        .json();

      if (body.errorDetails.length > 0) {
        throw new DetailedError(
          body.errorMessage || (body as unknown as string),
          body.errorDetails
        );
      }

      throw new Error(body.errorMessage || (body as unknown as string));
    }
    return undefined;
  },
};

client.use(throwOnError);
client.use(jwtMiddleware);

export default client;
