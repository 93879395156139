import api from "@/api/api";
import { userPreferencesSchema } from "@/schemas";
import LogRocket from "logrocket";

export const getUsers = async () => {
  const { data } = await api.GET("/api/User");

  return data!;
};

export const getUserPreferences = async () => {
  const { data } = await api.GET("/api/User/GetUserPreferences");

  let user;

  try {
    user = userPreferencesSchema.parse(JSON.parse(data!.response || ""));
    return user;
  } catch (error) {
    console.error("Failed to parse user preferences. Using default.");
    return userPreferencesSchema.parse({});
  }
};

export const getCurrentUser = async () => {
  try {
    const { data } = await api.GET("/api/User/me");
    LogRocket.identify(data!.email, {
      email: data!.email,
      name: data!.fullName,
    });

    return data;
  } catch (error) {
    console.error("Failed to get current user, redirecting to login");
    localStorage.removeItem("jwt");
    location.href = "/login";
    return null;
  }
};

/**
 * The email field is the "userId" 'temporarely'
 */
export const deleteUser = async (userId: string) => {
  await api.DELETE(`/api/User`, {
    body: {
      userId,
    },
  });
};
