import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/login")({
  component: () => <Outlet />,
  validateSearch: z.object({
    redirect: z.string().optional().catch("/"),
  }),
  beforeLoad: ({ context, location }) => {
    if (context.auth.isAuthenticated) {
      const search = new URLSearchParams(location.search);
      const to = search.get("redirect") || "/homepage";
      throw redirect({
        to,
      });
    }
  },
});
