import { z } from "zod";

export const createUserFormSchema = z
  .object({
    userId: z.string().min(1, "User Id is required"),
    username: z.string().min(3, "Username must be at least 3 characters"),
    fullName: z.string().min(1, "Full name is required"),
    contactEmail: z.string().email().optional(),
    isAdmin: z.boolean().optional(),
    accessLevel: z.enum(["Customer", "Job", "Global"]).optional(),
    customerId: z.string().nullable(),
    jobIds: z
      .array(
        z.object({
          jobName: z.string().nullable(),
          customerId: z.string().nullable(),
          jobId: z.string().nullable(),
        })
      )
      .nullable()
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.accessLevel === "Customer" && !data.customerId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Customer is required",
        path: ["customerId"],
      });
    }

    if (data.accessLevel === "Job") {
      if (!data.jobIds) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Jobs are required",
          path: ["accessLevel"],
        });
      } else {
        data.jobIds.forEach((job, index) => {
          if (!job.customerId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Customer is required",
              path: ["jobIds", index, "customerId"],
            });
          }

          if (!job.jobId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Job is required",
              path: ["jobIds", index, "jobId"],
            });
          }
        });
      }
    }
  });

export type CreateUserForm = z.infer<typeof createUserFormSchema>;

export const updatePasswordSchema = z
  .object({
    password: z.string().min(8, "Password must be at least 8 characters"),
    confirmPassword: z
      .string()
      .min(8, "Password must be at least 8 characters"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export type UpdatePasswordForm = z.infer<typeof updatePasswordSchema>;

export const utcDateString = z.string().refine(
  (val) => {
    const date = new Date(val);
    return !isNaN(date.getTime()) && val === date.toISOString();
  },
  {
    message: "Invalid UTC date string",
  }
);

export const viewTypeSchema = z.enum(["tickets", "list", "table"]);

export const validColumnOptionSchema = z.enum(["2", "4"]);

export type ValidColumnOption = z.infer<typeof validColumnOptionSchema>;

export const userPreferencesSchema = z.object({
  preferredView: z
    .object({
      desktop: viewTypeSchema,
      mobile: viewTypeSchema,
    })
    .default({
      desktop: "table",
      mobile: "tickets",
    }),
  columns: validColumnOptionSchema.default("2"),
  autoRefresh: z.boolean().default(false),
});

export type UserPreferences = z.infer<typeof userPreferencesSchema>;
