/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
export interface AuthContext {
  isAuthenticated: boolean;
  isAdmin: boolean;
  setJWT: (username: string | null) => void;
  JWT: string | null;
  parsedJWT: any;
}

const AuthContext = React.createContext<AuthContext | null>(null);

const useLocalStorage = (key: string, initialValue: string | null) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? item : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: string | null) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, value || "");
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as const;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [JWT, setJWT] = useLocalStorage("jwt", null);
  const isAuthenticated = !!JWT;
  const parsedJWT = JWT ? JSON.parse(atob(JWT.split(".")[1])) : null;
  const isAdmin = parsedJWT?.UserRoles === "Admin";

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, JWT, setJWT, parsedJWT, isAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
