import React from "react";

interface CustomerLogoProps {
  variant?: "small" | "large" | "contrast";
}

const CustomerLogo: React.FC<CustomerLogoProps> = ({ variant = "small" }) => {
  let logoPath;
  let size;
  switch (variant) {
    case "small":
      logoPath = "/lakeside_logo_small.svg";
      size = "2.5em";
      break;
    case "large":
      logoPath = "/lakeside_logo.svg";
      size = "10em";
      break;
    case "contrast":
      logoPath = "/lakeside_logo_small_contrast.svg";
      size = "3em";
      break;
    default:
      logoPath = "/lakeside_logo_small.svg";
      size = "3em";
      break;
  }

  if (import.meta.env.MODE !== "production") {
    logoPath = "/fake_logo.svg";
    size = "3em";
  }

  return (
    <img
      src={logoPath}
      style={{
        width: size,
        height: "auto",
      }}
      alt="Logo"
    />
  );
};

export default CustomerLogo;
