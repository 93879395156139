/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import type { Register } from "@tanstack/react-router";
import { t } from "i18next";
import { GridColDef } from "@mui/x-data-grid";

export function pathnameToDisplayName(
  pathname: keyof Register["router"]["routesByPath"]
) {
  switch (pathname) {
    case "/tickets":
      return t("eTicketing.Front.Label.routeDisplayNameTicketing");
    case "/users":
      return t("eTicketing.Front.Label.routeDisplayNameUsers");
    case "/homepage":
      return t("eTicketing.Front.Label.routeDisplayNameHomepage");
    default:
      return "";
  }
}

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

interface Options {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

export function useIntersectionObserver(
  target: React.RefObject<Element | null>,
  options: Options
): boolean {
  const [intersecting, setIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      setIntersecting(entries[0].isIntersecting);
    }, options);
    if (target.current) {
      observer.observe(target.current);
    }
    return () => observer.disconnect();
  }, [options, target]);

  return intersecting;
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Copy to clipboard successfully");
    },
    (err) => {
      console.error("Failed to copy to clipboard", err);
    }
  );
}

export const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

class IDXFormatter {
  private formatter: Intl.NumberFormat;

  constructor() {
    this.formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
    });
  }

  format = (value: number) => this.formatter.format(value).replace(/,/g, " ");
}

export const idxFormatter = new IDXFormatter();

export const lbsFormatter = new Intl.NumberFormat("en-US", {
  style: "unit",
  unit: "pound",
  unitDisplay: "short",
});

export const dateStringFormatter = (date: Date) =>
  date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

export const timeStringFormatter = (date: Date) =>
  date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
// tons formatter

export function formatTons(tons: number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(tons);
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/**
 * This function is an alternative to DataGrid's exportDataAsCsv function.
 * It needs the column definitions and the rows to export.
 */
export const exportAsCSV = (columns: GridColDef[], rows: any[]) => {
  const header = columns.map((column) => column.headerName).join(",");
  const csv = rows.map((row) =>
    columns.map((column) => row[column.field]).join(",")
  );
  csv.unshift(header);

  const blob = new Blob([csv.join("\n")], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "tickets.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
