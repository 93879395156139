/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  createFileRoute,
  redirect,
  Outlet,
  useRouterState,
  useRouter,
  useNavigate,
} from "@tanstack/react-router";

import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { pathnameToDisplayName } from "../utils";
import { styled, useTheme } from "@mui/material";

import { ChevronLeft, ChevronRight, Home } from "@mui/icons-material";
import CustomerLogo from "../components/common/CustomerLogo";
import { useQuery } from "@tanstack/react-query";
import usersQueries from "@/queries/users";
import useIsMobile from "@/hooks/useIsMobile";
import { t } from "i18next";
import UserMenu from "@/components/common/UserMenu";
import blue from "@/utils/colors/blue";

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: Layout,
});

const drawerWidth: number = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps & { open: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),

  // on mobile make zindex 0
  [theme.breakpoints.down("sm")]: {
    zIndex: 0,
    marginLeft: 0,
    width: "100%",
  },
}));

// RootLayout for authenticated users
function Layout() {
  const NAV_OPTIONS = [
    {
      name: t("eTicketing.Front.Label.navOptionHomepage"),
      path: "/homepage",
      icon: <Home htmlColor="white" />,
      svgPath: "/icon_ticket.svg",
    },
    {
      name: t("eTicketing.Front.Label.navOptionTicketing"),
      path: "/tickets",
      svgPath: "/icon_ticket.svg",
    },
    {
      name: t("eTicketing.Front.Label.navOptionUsers"),
      path: "/users",
      svgPath: "/icon_user.svg",
      requiresAdmin: true,
    },
  ];

  const [open, setOpen] = useState(false);
  const { resolvedLocation } = useRouterState();
  const router = useRouter();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isDesktop = !isMobile;

  const pathname =
    resolvedLocation.pathname as keyof typeof router.routesByPath;
  const displayName = pathnameToDisplayName(pathname);

  const { data: currentUser } = useQuery({
    ...usersQueries.currentUser(),
    select: (data) => {
      const isAdmin = data?.role === "Admin";

      return {
        isAdmin,
        fullName: data?.fullName,
        customerName: data?.customerName,
        visibility: data?.visibility,
      };
    },
  });

  const isAdmin = currentUser?.isAdmin;

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const closeDrawer = () => setOpen(false);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box>
          <AppBar open={open} position="absolute">
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                color="inherit"
                onClick={toggleDrawer}
                edge="start"
                sx={{
                  marginLeft: 0,
                  marginRight: 4,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  display="flex"
                  flex={1}
                  gap={2}
                  overflow="hidden"
                  alignItems="center"
                >
                  <CustomerLogo variant="small" />
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      fontSize="18px"
                    >
                      {displayName}{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontSize="18px"
                      component="h2"
                      noWrap
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      fontWeight={400}
                      overflow="hidden"
                      display={
                        currentUser?.visibility === "Customer"
                          ? "block"
                          : "none"
                      }
                    >
                      {currentUser?.customerName}
                    </Typography>
                  </Box>
                  <UserMenu />
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <Box>
          <MuiDrawer
            variant={isMobile ? "temporary" : "permanent"}
            sx={{
              "& .MuiButtonBase-root:hover": {
                backgroundColor: blue[800],
              },
              "& .Mui-selected": {
                backgroundColor: `${blue[700]} !important`,
              },
              "& .MuiDrawer-paper": {
                backgroundColor: blue["900"],
                color: (theme) => theme.palette.getContrastText(blue["900"]),
                whiteSpace: "nowrap",
                width: (theme) => {
                  if (open) {
                    return drawerWidth;
                  }

                  return theme.spacing(14);
                },
                boxSizing: "border-box",
                overflow: "hidden",
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.shorter,
                }),
              },
            }}
            open={open}
            onClose={closeDrawer}
          >
            <DrawerHeader
              sx={{
                bgcolor: "primary.main",
              }}
            >
              <Box
                sx={{
                  color: "white",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pl: 1,
                }}
              >
                <Typography variant="h6">
                  {t("eTicketing.Front.Label.sideMenuTitle")}
                </Typography>
                <IconButton color="inherit" onClick={toggleDrawer}>
                  {theme.direction === "rtl" ? (
                    <ChevronRight />
                  ) : (
                    <ChevronLeft />
                  )}
                </IconButton>
              </Box>
            </DrawerHeader>
            <Divider />
            <List component="nav">
              {NAV_OPTIONS.filter((option) => {
                if (option.requiresAdmin) {
                  return isAdmin;
                }
                return true;
              }).map((navOption) => (
                <ListItemButton
                  key={navOption.path}
                  selected={pathname === navOption.path}
                  onClick={() => {
                    navigate({
                      to: navOption.path,
                    });
                    closeDrawer();
                  }}
                >
                  <ListItemIcon>
                    {navOption.icon ? (
                      navOption.icon
                    ) : (
                      <img src={navOption.svgPath} alt={navOption.name} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={navOption.name} />
                </ListItemButton>
              ))}
            </List>
          </MuiDrawer>
        </Box>

        <Box
          component="main"
          sx={{
            backgroundColor: "background.paper",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            marginLeft: (theme) => {
              if (isDesktop && open) {
                return `${drawerWidth}px`;
              }

              if (isMobile) {
                return 0;
              }

              return theme.spacing(14);
            },
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DrawerHeader />
          <Box
            sx={{
              overflow: "auto",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </div>
  );
}
